.admin__list__bar
{
	//margin: 0px;
	//border: none;
	position: relative;
	padding-top: rem-calc(45);

	h1 
	{
		display: inline-block;
	}

	.generate__PDF
	{
		//float: right;
		font-size: rem-calc(14);
		//position: relative;
		position: absolute !important;
		//top: rem-calc(5);
		top: initial !important;
		right: 0px !important;
		bottom: rem-calc(10) !important;
		color: $blue;

		.fa-file-pdf-o
		{
			font-size: rem-calc(20);
			margin-left: rem-calc(15);
			position: relative;
			top: rem-calc(2);
		}
	}

	.nested__search__icon
	{
		float: right;
		position: relative;
		right: rem-calc(12);
		bottom: rem-calc(29);
	}

	.heading__summary
	{
		font-size: rem-calc(14);
		line-height: rem-calc(20);
		display: inline-block;
		vertical-align: top;
		color: $light-grey;
	}

	.bar__middle
	{
		.visual__search__button__container
		{
			.button__inline__block
			{
				width: 100%;
				height: rem-calc(39);
				//padding: 0px rem-calc(20) !important;
			}
		}
	}

	@include breakpoint(large down) {
		.noflex-medium {
			height: 160px;
			.bar__left-container, .bar__right-container {
				width: 100%;
				max-width: none;
			}
			.bar__right-container {
				clear: both;
				padding-top: 20px;
				.bar__right {
					.button__group {
						left: 0;
					}
				}
			}
		}
	}
	//.bar__left-container {
	//	@include breakpoint(large down) {
	//		width: 100%;
	//	}
	//}
	//.bar__right-container {
	//	@include breakpoint(large down) {
	//		width: 100%;
	//		clear: both;
	//		.bar__right {
	//			.button__group {
	//				left: 0;
	//			}
	//		}
	//	}
	//}

	.bar__right
	{
		.button__group
		{
			position: absolute;
			right: 0;
		}
	}
}
.button__group 	{
	.button__inline__block {
		width: 100%;
		padding-top: rem-calc(13) !important;
	}
	position: absolute;
	right: 0;
	&.triple-button {
		width: 374px;
	}
}


.sm-margin-left {
	margin-left: 15px;
}


/* over-ride display: flex */
#main__content #content__block .row.noflex-medium {
	@include breakpoint(large down) {
		display: block;
		.column, .columns {
			display: block;
		}
	}
}









