

.ui-menu {
    .auto__complete__row__user {
        padding: 10px 0 5px 12px;
        border-style: solid;
        border-color: #eaeaea;
        border-width: 2px 2px 2px 2px;
        margin-top: -2px;
        p {
            margin-bottom: 0;
        }
        &.ui-state-active {
            background-color: $light-blue;
        }
        cursor: pointer;
    }
}
.ui-dialog {
    z-index:11;
}


