@mixin border-radius($radius) {
  	-webkit-border-radius: $radius;
  	border-radius: $radius;
  	background-clip: padding-box;
}

@mixin border-top-radius($radius) {
  	-webkit-border-top-right-radius: $radius;
  	border-top-right-radius: $radius;
   	-webkit-border-top-left-radius: $radius;
   	border-top-left-radius: $radius;
   	background-clip: padding-box;
}

@mixin border-right-radius($radius) {
  	-webkit-border-bottom-right-radius: $radius;
  	border-bottom-right-radius: $radius;
    -webkit-border-top-right-radius: $radius;
    border-top-right-radius: $radius;
    background-clip: padding-box;
}

@mixin border-bottom-radius($radius) {
  	-webkit-border-bottom-right-radius: $radius;
  	border-bottom-right-radius: $radius;
   	-webkit-border-bottom-left-radius: $radius;
   	border-bottom-left-radius: $radius;
   	background-clip: padding-box;
}

@mixin border-left-radius($radius) {
  	-webkit-border-bottom-left-radius: $radius;
  	border-bottom-left-radius: $radius;
    -webkit-border-top-left-radius: $radius;
    border-top-left-radius: $radius;
    background-clip: padding-box;
}

@mixin placeholder 
{
    &::-webkit-input-placeholder 
    {  
        @content; 
    }

    &:-moz-placeholder           
    {  
        @content;
    }

    &::-moz-placeholder          
    {  
        @content; 
    }

    &:-ms-input-placeholder      
    {  
        @content; 
    } 
}