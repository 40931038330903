/*@font-face {
  font-family: 'kontrast_icons_v1';
  src:  url('/fonts/kontrast_icons_v1.eot?tjg1rk');
  src:  url('/fonts/kontrast_icons_v1.eot?tjg1rk#iefix') format('embedded-opentype'),
    url('/fonts/kontrast_icons_v1.ttf?tjg1rk') format('truetype'),
    url('/fonts/kontrast_icons_v1.woff?tjg1rk') format('woff'),
    url('/fonts/kontrast_icons_v1.svg?tjg1rk#kontrast_icons_v1') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts * /
  font-family: 'kontrast_icons_v1' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== * /
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add_2:before {
  content: "\e900";
}
.icon-add:before {
  content: "\e901";
}
.icon-bookings:before {
  content: "\e902";
}
.icon-customers:before {
  content: "\e903";
}
.icon-edit:before {
  content: "\e904";
}
.icon-menu:before {
  content: "\e905";
}
.icon-products:before {
  content: "\e906";
}
.icon-quotes:before {
  content: "\e907";
}
.icon-search:before {
  content: "\e908";
}
.icon-settings:before {
  content: "\e909";
}
.icon-transport:before {
  content: "\e90a";
}*/



@font-face {
  font-family: 'Kontrast_icons_v2';
  src:  url('/fonts/Kontrast_icons_v2.eot?keo718');
  src:  url('/fonts/Kontrast_icons_v2.eot?keo718#iefix') format('embedded-opentype'),
    url('/fonts/Kontrast_icons_v2.ttf?keo718') format('truetype'),
    url('/fonts/Kontrast_icons_v2.woff?keo718') format('woff'),
    url('/fonts/Kontrast_icons_v2.svg?keo718#Kontrast_icons_v2') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Kontrast_icons_v2' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add_2:before {
  content: "\e900";
}
.icon-add:before {
  content: "\e901";
}
.icon-arrow:before {
  content: "\e902";
}
.icon-bookings:before {
  content: "\e903";
}
.icon-customers:before {
  content: "\e904";
}
.icon-dashboard:before {
  content: "\e905";
}
.icon-delete:before {
  content: "\e906";
}
.icon-dropdown:before {
  content: "\e907";
}
.icon-edit:before {
  content: "\e908";
}
.icon-info:before {
  content: "\e909";
}
.icon-menu:before {
  content: "\e90a";
}
.icon-pdf:before {
  content: "\e90b";
}
.icon-price:before {
  content: "\e90c";
}
.icon-products:before {
  content: "\e90d";
}
.icon-quotes:before {
  content: "\e90e";
}
.icon-reports:before {
  content: "\e90f";
}
.icon-search:before {
  content: "\e910";
}
.icon-settings:before {
  content: "\e911";
}
.icon-transport:before {
  content: "\e912";
}
.icon-update:before {
  content: "\e913";
}
.icon-users:before {
  content: "\e914";
}
.icon-website:before {
  content: "\e915";
}





@font-face 
{
    font-family: 'SofiaPro-SemiBold';
    src: url('/fonts/SofiaPro-SemiBold.eot?#iefix') format('embedded-opentype'),  
       url('/fonts/SofiaPro-SemiBold.otf')  format('opentype'),
       url('/fonts/SofiaPro-SemiBold.woff') format('woff'), 
       url('/fonts/SofiaPro-SemiBold.ttf')  format('truetype'), 
       url('/fonts/SofiaPro-SemiBold.svg#SofiaPro-SemiBold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face 
{
    font-family: 'SofiaPro-Bold';
    src: url('/fonts/SofiaPro-Bold.eot?#iefix') format('embedded-opentype'),  
       url('/fonts/SofiaPro-Bold.otf')  format('opentype'),
       url('/fonts/SofiaPro-Bold.woff') format('woff'), 
       url('/fonts/SofiaPro-Bold.ttf')  format('truetype'), 
       url('/fonts/SofiaPro-Bold.svg#SofiaPro-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face 
{
    font-family: 'SofiaPro-UltraLight';
    src: url('/fonts/SofiaPro-UltraLight.eot?#iefix') format('embedded-opentype'),  
       url('/fonts/SofiaPro-UltraLight.otf')  format('opentype'),
       url('/fonts/SofiaPro-UltraLight.woff') format('woff'), 
       url('/fonts/SofiaPro-UltraLight.ttf')  format('truetype'), 
       url('/fonts/SofiaPro-UltraLight.svg#SofiaPro-UltraLight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face 
{
    font-family: 'SofiaPro-Light';
    src: url('/fonts/SofiaPro-Light.eot?#iefix') format('embedded-opentype'),  
       url('/fonts/SofiaPro-Light.otf')  format('opentype'),
       url('/fonts/SofiaPro-Light.woff') format('woff'), 
       url('/fonts/SofiaPro-Light.ttf')  format('truetype'), 
       url('/fonts/SofiaPro-Light.svg#SofiaPro-Light') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face 
{
    font-family: 'SofiaPro-ExtraLight';
    src: url('/fonts/SofiaPro-ExtraLight.eot?#iefix') format('embedded-opentype'),  
       url('/fonts/SofiaPro-ExtraLight.otf')  format('opentype'),
       url('/fonts/SofiaPro-ExtraLight.woff') format('woff'), 
       url('/fonts/SofiaPro-ExtraLight.ttf')  format('truetype'), 
       url('/fonts/SofiaPro-ExtraLight.svg#SofiaPro-ExtraLight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face 
{
    font-family: 'SofiaMedium';
    src: url('/fonts/SofiaMedium.eot?#iefix') format('embedded-opentype'),  
       url('/fonts/SofiaMedium.woff') format('woff'), 
       url('/fonts/SofiaMedium.ttf')  format('truetype'), 
       url('/fonts/SofiaMedium.svg#.') format('svg');
    font-weight: normal;
    font-style: normal;
}
