html,
body
{
	min-height: 100%;
}


body
{
	margin-bottom: 0;
	background: $off-white;
	font-family: 'SofiaPro-Light';
	// overflow-y: scroll !important;
	font-size: 16px;
}

.admin-header {
	.logo {
		margin-left: 1rem;
	}
}



#main__content
{
	height: 100%;
	padding-top: rem-calc(80);
	@include flex-grid-row('collapse','expand');
	background: $off-white;


	#content__block
	{
		width: 100%;
		padding-left: rem-calc(100);
		padding-bottom: rem-calc(50);

		@include breakpoint(large)
		{
			padding-left: rem-calc(200);
		}

		@include breakpoint(xlarge)
		{
			padding-left: rem-calc(270);
		}

		.inner__content__block
		{
			padding-bottom: rem-calc(100);
			width: auto;
			max-width: none;
			padding-left: 0.9375rem;
			padding-right: 0.9375rem;
			& > .row {
				& > .column, & > .columns {
					padding-left: 0;
					padding-right: 0;
					&.pad-left {
						padding-left: 25px;
					}
				}
			}

			.missing {
				margin-bottom: 20px;
				> a
				{
					margin-bottom: rem-calc(10);

					@include breakpoint(large up)
					{
						margin-bottom: rem-calc(20);
					}

					&:last-child
					{
						margin-bottom: 0px;
					}
				}
				.button__inline__block {
					padding-top: 10px;
					@include breakpoint(medium down) {
						padding-top: 15px;
					}
				}
			}

			#signature-view
			{
				margin-bottom: rem-calc(10);
			}
		}

		.row
		{
			@include flex-grid-row('collapse','expand');
		}

		h1
		{
			margin: 0;
			color: $blue;
			font-family: 'SofiaPro-Light';
			font-size: rem-calc(18);
		}
	}
}



// Override for sidebar
body
{
	&.has__sidebar
	{
		#main__content
		{
			#content__block
			{
				/*
				padding: 0 0 rem-calc(30) rem-calc(225);

				@include breakpoint(xlarge up)
				{
					padding: 0 0 rem-calc(30) rem-calc(306);
				}

				@include breakpoint(medium only)
				{
					padding-right: 0px !important;
				}
				*/

				.inner__content__block
				{
					padding-bottom: 0;
				}

				.scrollable
				{
					padding-bottom: rem-calc(50);
				}
			}
		}


		// Sidebar override
		.side__bar
		{
			&.summary__bar
			{
				margin-top: 0;

			}
		}
	}
}

//handy classes

// overflow-x scroll, at least temporarily, as a fix for tables that demand more width than that which the screen size offers.
.contains__wide__table
{
	overflow-x: scroll;
	overflow-y: hidden;
}

.border__standard
{
	border: 2px solid $blue;
}

.no__background
{
	background-color: transparent;
}

.hidden
{
	visibility: hidden;
}

.display__none
{
	display: none;
}

.text__align__left
{
	text-align: left;
}

.text__align__right
{
	text-align: right;
}


h1, h2, h3, h4, h5, p, li, td {
	font-family: 'SofiaPro-Light';
}
h1 {
	font-size: 48px;
	#main__content #content__block & {
		font-size: 18px;
	}
}
h2 {
	font-size: 40px;
}
h3 {
	font-size: 31px;
}
h4 {
	font-size: 25px;
}
p, li, td, th, .table__list thead tr th, .table__list tbody tr td {
	font-size: 14px;
	line-height: 1.3;
	&.small-p, .small-p & {
		font-size: 13px;
	}
	&.lg-p, .lg-p & {
		font-size: 16px;
	}
}
@media (max-width: $mobile-max-width) {
	body {
		font-size: 12px;
	}
	h1 {
		font-size: 23px;
		#main__content #content__block & {
			font-size: 15px;
		}
	}
	h2 {
		font-size: 20px;
	}
	h3 {
		font-size: 17px;
	}
	h4 {
		font-size: 14px;
	}
	p, li, td, th, .table__list thead tr th, .table__list tbody tr td, .button__large__blue {
		font-size: 11px;
		&.small-p, .small-p & {
			font-size: 10px;
		}
		&.lg-p, .lg-p & {
			font-size: 14px;
		}
	}
}

.spacer-20 {
	width: 100%;
	clear: both;
	height: 20px;
}
.spacer-50 {
	width: 100%;
	clear: both;
	height: 50px;
}



input[type='submit'] {
	cursor: pointer;
}




.flex-center {
	display: flex;
	align-items: center;
	text-align: center;
	height: 100%;
	.flex-center-item {
		display: inline-block;
		width: 320px;
		margin: 0 auto 50px;
		max-width: 90%;
	}
}


.flex-spacebetween {
	display: flex;
	justify-content: space-between;
}
.flex-item {
	display: inline-block;
}

.menu-trigger {
	cursor: pointer;
	padding: 5px;
	.button__group {
		margin: 0 auto;
		position: static;
		display: inline-block;
		.button__inline__block {
			padding: 10px 23px 4px !important;
			height: 45px;
			.icon {
				&:before {
					font-size: 17px;
					position: relative;
					top: 2px;
				}
			}
		}
	}
	position: relative;
	.popup-menu {
		position: absolute;
		display: none;
		background-color: $blue;
		padding: 10px 15px;
		width: 200px;
		z-index: 10;
		&.showing {
			display: block;
		}
		a {
			display: block;
			padding: 8px 15px;
		}
		a:not(.not-active) {
			display: block;
			color: $light-blue;
			transition: all 0.1s linear;
			border-radius: 3px;
			&:hover {
				color: #fff;
				background-color: lighten($blue, 5%);
			}
		}
		a.not-active {
			color: mix($light-blue, $blue, 90%);
		}
	}
}

.button__inline__block + .menu-trigger {
	margin-left: 12px;
}


[class*="column"]+[class*="column"]:last-child {
	float: left;
}


.display-none {
	display: none;
}

.padded-white-region {
	padding: 15px;
	background-color: #fff;
	ul.accordion {
		li.accordion-item {
			.accordion-clickline {}
			.accordion-content {
				.cell-inner {
					border-bottom: 2px solid #d9eaec;
                    padding-bottom: 50px;
                    margin-bottom: 20px;
					.img-contain {
						position: relative;
						.hover-button {
							display: none;
							position: absolute;
							top: 5px;
							right: 5px;
							background-color: #fff;
							border-radius: 99px;
							box-shadow: 0 0 2px 2px #fff;
							.icon:before {
								font-size: 30px;
								line-height: 0.9;
								position: relative;
								top: 3px;
								transition: color 0.1s linear;
								color: $light-blue;
							}
						}
					}
					.below-img {
						height: 80px;
                        h4 {
                            font-size: 20px;
                        }
					}
					&:hover {
						.hover-button {
							display: block;
							&:hover .icon:before {
								color: #222;
							}
						}
					}
                    @media (max-width: 1100px) {
						.img-contain {
							.hover-button {
								display: block;
								.icon:before {
									color: #222;
								}
							}
						}
                    }
				}
			}
		}
	}
	&.two-thirds {
		width: 70%;
		float: left;
	}
}
.right-of-padded-white {
	float: left;
	width: calc(30% - 20px);
	margin-left: 20px;
	box-sizing: border-box;
	padding: 15px;
	background-color: #fff;

	table {
		margin-top: 10px;
		tr {
			td {
				vertical-align: top;
				padding-bottom: 8px;
				&:first-child {
					display: table-cell;
					font-weight: bold;
					width: 120px;
				}
			}
			&:nth-child(even) {
				background: #fff;
			}
		}
	}
}

.column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
  float: left;
}







