
$sidebar-width: 500px;
$sb-image-height: 100px;

.side__bar
{
	width: rem-calc($sidebar-width);
	right: rem-calc(0 - $sidebar-width) !important;
	margin-left: rem-calc(30);
	background-color: $white;
	font-size: rem-calc(13);
	color: $blue;
	transition: right 0.2s ease;

	&.is-open
	{
		right: -20px !important;

		.hide__button__wrapper
		{
			.hide__button
			{
				.arrow
				{
					transform: scaleX(-1) rotate(-45deg);
					left: rem-calc(25);
				}
			}
		}
	}

	.inner
	{
		height: 100%;
		padding-top:rem-calc(42);
		padding-right: rem-calc(50);
		padding-bottom: rem-calc(200);
		padding-left: rem-calc(30);
		overflow-y: scroll;
	}

	.hide__button__wrapper
	{
		/* Template:
			<div class="hide__button__wrapper">
		        <div class="hide__button">
		            <div class="arrow"></div>
		        </div>
		    </div>
		*/

		width: 0;
		height: 0;
		//position: relative;
		top: rem-calc(40);
		right: rem-calc(40);

		.hide__button
		{
            cursor: pointer;
			width: rem-calc(70);
			height: rem-calc(50);
			position: absolute;
			//top: rem-calc(-60);
			top: rem-calc(30);
			//right: rem-calc(-10);
			right: rem-calc($sidebar-width);
			border-radius: rem-calc(10) 0 0 rem-calc(10);
			background-color: $light-blue;
			opacity: 0.5;
            transition: opacity 0.1s linear;
            &:hover {
                opacity: 1;
            }

			.arrow
			{
				width: rem-calc(20);
				height: rem-calc(20);
				position: relative;
				top: rem-calc(14);
				left: rem-calc(30);
				border-top: 2px solid $white;
				border-left: 2px solid $white;
				transform: rotate(-45deg);
			}
		}
	}

	.side__bar__heading
	{
		font-family: 'SofiaPro-Light';
		font-size: rem-calc(18);
		padding-bottom: rem-calc(8);
		margin-bottom: rem-calc(25);
		border-bottom: 1px solid $xlight-grey;
	}

	table,
	tbody,
	th,
	tr,
	td
	{
		background: none !important;
		border: none;
	}

	table
	{
		margin: 0;
	}

	td
	{
		padding: 0;

		&:nth-child(odd)
		{
			display: none;
		}
	}

	fieldset
	{
		margin-bottom: rem-calc(25);

		legend
		{
			color: $grey;
			margin-bottom: rem-calc(10);
			width: 100%;

			.icon-edit
			{
				float: right;
				font-size: rem-calc(16);
				color: $light-blue;
				margin-right: rem-calc(10);
				position: relative;
				top: rem-calc(1);
			}
		}
	}

	.block__3
	{
		tr:nth-last-child(1),
		tr:nth-last-child(2),
		tr:nth-last-child(3)
		{
			display: inline-block;
		}

		tr:nth-last-child(3),
		tr:nth-last-child(2)
		{
			td:nth-last-child(1)
			{
				&::after
				{
					content: ".";
					margin-right: rem-calc(5);
				}
			}
		}
	}

	.block__4
	{
		legend
		{
			margin: 0;
			padding-bottom: rem-calc(10);
			//border-bottom: 1px solid $light-grey;
		}

		.add__room
		{
			margin: rem-calc(20) 0;

			.button__wrapper
			{
				display: inline-block;
			}

			.add__room__label
			{
				margin-left: rem-calc(10);
				position: relative;
				top: rem-calc(5);
			}
		}
	}

	&.alert__box__container
	{
		padding: 0;
		background: transparent;
	}

	&.summary__bar
	{
		height: 100%;
		position: fixed;
		margin-top: rem-calc(-30);
		box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
		z-index: 5;
		.missing
		{
			padding: rem-calc(50) rem-calc(10);

			p
			{
				margin: 0;
			}
		}
	}

	.itemline {
		> div {
			float: left;
			padding: 10px 0;
			&.qty {
				width: 25px;
			}
			&.itemname {
				font-size: 14px;
				font-weight: 600;
				color: $blue;
				width: calc(100% - 105px);
			}
			&.remove {
				width: 80px;
			}
			&.qty-adjust-buttons {
				width: 60px;
				text-align: right;
				float: right;
				a {
					span:before {
						font-size: 22px;
						color: $xlight-grey;
						transition: color 0.2s linear;
						line-height: 0.5;
					}
					&:first-child {
						margin-right: 15px;
					}
					&:hover {
						span:before {
							color: $primary-color;
						}
					}
				}
			}
		}
		&.shallow > div {
			padding: 2px 0;
		}
	}
}

.accordion-item {
	border: none !important;
	border-bottom: 2px solid $blue !important;
	padding: 5px 0 15px !important;
	.accordion-clickline
	{
		padding: 5px 0 10px;
		display: block;
		span {
			&.caret {
				margin-right: 8px;
			}
			&.room-type {
				font-size: 17px;
				font-weight: 600;
				color: $blue;
			}
			&.item-count {
				color: $blue;
				margin-left: 5px;
			}
		}
	}
	.accordion-content {
		.form__row {
			.images {
				.clearing-thumbs {

				}
			}
		}
		.summary-gallery {
			padding-bottom: 20px;
			.images__list--image {
			}
			.image-container {
				cursor: pointer;
				width: calc(50% - 5px);
				height: $sb-image-height;
				margin-right: 10px;
				&:nth-child(2), &.dropzone-container {
					margin-right: 0;
				}
				float: left;
				margin-bottom: 10px;
				opacity: 1;
				transition: opacity 0.1s linear;
				&:hover {
					opacity: 0.6;
				}
				position: relative;
				.deleteImage {
					position: absolute;
					top: 5px;
					right: 5px;
					display: block;
					text-align: center;
					width: 25px;
					height: 25px;
					padding-top: 5px;
					background-color: #fff;
					border-radius: 4px;
					&:hover {
						background-color: $red;
						color: #fff;
					}
				}
				.image-dropzone {
					height: $sb-image-height;
				}
				.has-tip {
					border-bottom: none;
					cursor: pointer;
				}
			}
		}
	}
}
ul.accordion li.accordion-item {
	&, &.is-active {
		margin-bottom: 20px;
	}
}


.mfp-bg {
    background-color: #fff;

}
.mfp-figure:after {
    box-shadow: none;
}






.side__bar {
	table.fname-val {
		margin-top: 10px;
		tr {
			td {
				vertical-align: top;
				padding-bottom: 8px;
				&:first-child {
					display: table-cell;
					font-weight: bold;
					width: 120px;
				}
			}
		}
	}
}



