.auto__complete__row__product{
  background-color:white;
  border-bottom:1px dashed antiquewhite;
  border-left:1px dashed antiquewhite;
  border-right:1px dashed antiquewhite;
  width:10%;
  &:hover
  {
    background-color:$xlight-grey;
  }
}

.auto__complete__row__user{
  background-color:white;
  border-bottom:1px dashed antiquewhite;
  border-left:1px dashed antiquewhite;
  border-right:1px dashed antiquewhite;
  width:20%;
  &:hover
  {
    background-color:$xlight-grey;
  }
}