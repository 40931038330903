.ui-dialog
{
	width: rem-calc(500) !important;
	padding: rem-calc(30);
	@include border-radius(10px);
	background: $white;
	box-shadow: 0 0 15px rgba($black, 0.1);

	.ui-dialog-titlebar
	{
		margin-bottom: rem-calc(20);

		.ui-dialog-title
		{
			color: $blue;
			font-weight: 700;
			font-size: rem-calc(18);
		}
	}

	.ui-dialog-titlebar-close
	{
		float: right;
		width: rem-calc(25);
		height: rem-calc(25);
		text-indent: -9999px;
		position: relative;

		&:focus
		{
			outline: none !important;
		}

		&:after
		{
			display: block;
			width: rem-calc(25);
			height: rem-calc(25);
			content: '\e906';
			color: $blue;
			text-indent: 0;
			font-family: 'Kontrast_icons_v2';
			position: absolute;
			left: 0;
			top: 0;
		}
	}

	.ui-dialog-buttonset
	{
		margin-top: rem-calc(20);

		.ui-button
		{
			@extend .button__large__blue;

			&:focus
			{
				outline: none !important;
			}

			&:last-child
			{
				float: right;
				@extend .button__normal;
			}
		}
	}
}