.missing
{
	padding: 120px;
	background: darken($off-white, 3%);
	border: 2px dashed darken($off-white, 8%);
	text-align: center;

	.button__large__blue
	{
		&:hover
		{
			color: $white;
		}
	}
}

//.fixed__height__div
//{
//	height:10% !important;
//	overflow-y:scroll;
//}
//
//.fixed__height__div::-webkit-scrollbar {
//	width: 5px;
//}
//
//.fixed__height__div::-webkit-scrollbar-track {
//	background: #ddd;
//}

.table__list
{
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	//border-top: 1px solid lighten($black, 90%);
	//border-bottom: 1px solid lighten($black, 90%);
	border: 0;
	background-color: transparent;

	thead
	{
		border: none;
		background: none;

		tr
		{
			th
			{
				padding: rem-calc(5);
				text-transform: none;
				font-weight: 500;

				&:first-child
				{
					width: rem-calc(300);
					padding-left: 0;
				}

				&.last
				{
					width: rem-calc(150);
				}

				a
				{
					&:after
					{
						display: inline-block;
						margin-left: rem-calc(5);
						color: lighten($black, 80%);
						font-family: 'FontAwesome';
						content: '\f0dc';
					}

					&.asc
					{
						&:after
						{
							color: $light-blue;
							content: '\f0d8' !important;
						}
					}

					&.desc
					{
						&:after
						{
							color: $light-blue;
							content: '\f0d7' !important;
						}
					}
				}
			}
		}
	}

	tbody
	{
		border: none;
		background: none;

		tr
		{
			td
			{
				padding: rem-calc(16) rem-calc(5) rem-calc(10);
				border-top: 1px solid $medium-blue;
				border-bottom: 1px solid $medium-blue;
				vertical-align: top;

				&:first-child
				{
					padding-left: rem-calc(15);
					border-left: 1px solid $medium-blue;
				}

				&:last-child
				{
					border-right: 1px solid $medium-blue;

					@include breakpoint(medium down)
					{
						text-align: right;
					}

					a
					{
						display: inline-block;
						margin-right: rem-calc(20);
						color: $blue;

						&.confirm
						{
							color: $medium-blue;
						}
					}
				}

				input[type="checkbox"]
				{
					margin: 0;
				}

				.button__remove
				{
					@extend .button__normal;
					height: rem-calc(30);
					margin-left: rem-calc(5);
					margin-right: rem-calc(5);
					padding: rem-calc(5) rem-calc(10);
					font-size: rem-calc(12);
					line-height: rem-calc(20);
				}

				.button__large__blue 
				{
					width: auto !important;
					height: auto !important;
					margin-right: rem-calc(4) !important;
					padding: rem-calc(8) rem-calc(12) rem-calc(6) rem-calc(12) !important;

					@include breakpoint(large up)
					{
						padding-left: rem-calc(20) !important;
						padding-right: rem-calc(20) !important;
					}
				}

				&.draft,
				&.appointment
				{
					color: $red;

					span
					{
						display: inline-block;
						width: rem-calc(10);
						height: rem-calc(10);
						margin-right: rem-calc(5);
						border-radius: 50%;
						background: $red;
					}
				}

				&.accepted,
				&.sent,
				&.paid
				{
					color: $green;

					span
					{
						display: inline-block;
						width: rem-calc(10);
						height: rem-calc(10);
						margin-right: rem-calc(5);
						border-radius: 50%;
						background: $green;
					}
				}
			}

			&:hover
			{
				td
				{
					//background-color: lighten($black, 98%);
					//box-shadow: lighten($black, 90%) 0 -1px 0 inset, lighten($black, 90%) 0 1px 0 inset;
				}
			}

			&:nth-child(even)
			{
				//background-color: $white;
			}

			&.last
			{
				width: rem-calc(200);
			}
		}

		tr.warning {
			border-left: $warning-color 3px solid;

			td.warning__cell {
				background: lighten($warning-color ,25%) !important;
			}
			td.alert__cell {
				 background: lighten($error-color ,25%) !important;
			}

			td.success__cell {
				background: lighten($success-color ,25%) !important;
			}
		}
		tr.error {
			border-left: $error-color 3px solid;

			td.warning__cell {
				background: lighten($warning-color ,25%) !important;
			}
			td.alert__cell {
				background: lighten($error-color ,25%) !important;
			}
		}
		tr.success {
			border-left: $success-color 3px solid;

			td.warning__cell {
				background: lighten($warning-color ,25%) !important;
			}
			td.alert__cell {
				background: lighten($error-color ,25%) !important;
			}

			td.success__cell {
				background: lighten($success-color ,25%) !important;
			}
		}
	}
}


.admin__list__bar
{
	margin-bottom: rem-calc(25);
	padding: rem-calc(15) 0;
	border-bottom: 2px solid $blue;
	/*border-top: 1px solid lighten($black, 90%);
	border-bottom: 1px solid lighten($black, 90%);
	background: $white;*/

	.grid
	{
		display: flex;
		justify-content: space-between;

		&.spacer
		{
			margin-top: rem-calc(10);
		}

		.col
		{
			display: inline-block;
			min-width: 0;
			//@extend .align-middle;

			&.no-col
			{
				flex: none;
			}

			&.top
			{
				vertical-align: top;
				align-self: flex-start;
			}

			.title
			{
				display: flex;
				top: 0;
				//align-items: flex-start;
				//@extend .align-middle;
				//text-transform: uppercase;
				font-weight: 700;
				font-size: rem-calc(14);

				&.results
				{
					text-transform: none;
					font-weight: 500;
					font-size: rem-calc(12);
				}

				.icon
				{
					flex-shrink: 0;

					span
					{
						width: rem-calc(35);
						height: rem-calc(35);
						margin-right: rem-calc(10);
						@include border-radius(3px);
						background: $black;
						color: $white;
						font-size: rem-calc(20);
						text-align: center;
						line-height: rem-calc(35);
					}
				}

				h1
				{
					flex: 1;
					margin: 0;
					min-width: 0;
				}

				span.seo {
					padding-left: 16px;
					&:before {
						font-size: 18px;
						color: $blue;
					}
					display: none;
					@include breakpoint(medium down) {
						display: inline;
					}
				}
			}

			&.last
			{
				flex: none;
				align-self: flex-start;
			}

			// Search form
			form
			{
				font-size: 0;
				
				input[type="text"],
				select
				{
					width: rem-calc(150);
				}

				.inline__block
				{
					display: inline-block;
					//width: rem-calc(60);
					margin-left: -1px;
					padding: 0 rem-calc(10);
					border: 1px solid lighten($black, 90%);
					//@include border-left-radius(3px);
					//@include border-top-radius(0);
					background: $white;
					color: lighten($black, 60%);
					font-size: rem-calc(12);
					vertical-align: middle;

					label,
					input
					{
						display: inline-block;
						margin: 0;
						padding: 0;
						color: lighten($black, 60%);
						font-weight: 400;
						font-size: rem-calc(12);
						line-height: rem-calc(40);
						vertical-align: middle;
					}

					input
					{
						margin-left: rem-calc(10);
					}

					input[type="checkbox"]
					{
						width: auto;
						height: auto;
						position: relative;
						left: 0;
					}
				}
			}
			padding-top: 12px;
			&.button__group {
				padding-top: 0;
				display: flex;
				position: static;
			}
		}
	}

	.list__menu
	{
		font-size: rem-calc(14);
	}

    .bar-with-search {
        display: flex;
		justify-content: space-between;
        .title, .bar__middle, .button__group {
            display: inline-block;
        }
		> .button__group {
			display: flex;

		}
        .bar__middle {
            flex-grow: 0.7;
			.before-2-buttons {
				width: calc(100% - 230px);
				@include breakpoint(medium down) {
					width: calc(100% - 190px);
				}
			}
        }
		.button__group {
			position: relative;
		}
    }
}


.admin__grid
{
	//@include flex-grid-row('nest', 'expand');
	//@include flex-grid-layout(4, 'gridPanel');

	h4
	{
		color: $blue;
		font-weight: 400;
		font-family: 'SofiaPro-Light';
		font-size: rem-calc(20);
	}

	.gridPanel
	{
		//@include flex-grid-column();

		&.unavailable
		{
			.gridPanel__inner
			{
				.gridPanel__image
				{
					.gridPanel__icon
					{
						display: none;
					}

					.unavailable__overlay
					{
						display: flex;
					}
				}
			}
		}

		.gridPanel__inner
		{
			//background-color: $white;
			//box-shadow: 0 0 2px rgba($black, 0.2);
			position: relative;
			cursor: pointer;
			//text-align: center;

			.gridPanel__image
			{
				width: 100%;
				padding-bottom: 100%;
				position: relative;
				/*background-color: darken($white, 20%);
				background-size: cover;
				background-position: 50% 50%;
				background-repeat: no-repeat;
				background-image: url('/assets/img/bg.jpg');*/

				background-color: $white;
				//background-size: rem-calc(350);
				background-size: 80%;
				background-position: 50% 50%;
				background-repeat: no-repeat;

				&.image
				{
					background: $white;

					img
					{
						width: 60px;
						height: 100%;
						margin: 0 auto;
						//padding: 0 rem-calc(80);
						position: absolute;
						bottom: 0;
						left: 0;
						top: 0;
						right: 0;
					}

				}
				input[type="checkbox"] {
					display: none;
				}
				.checkmark {
					width: 100%;
					position: absolute;
					bottom: 0;
					text-align: right;
					color: #fff;
					font-size: 25px;
					.fa {
						padding-right: 10px;
					}
				}
				&.cb-checked.image {
					background-color: $light-blue;
				}
				&.cb-not-checked {
					img {
						opacity: 0.4;
					}
				}

				.gridPanel__icon
				{
					height: 0px;
					text-align: right;

					span 
					{
						position: relative;
						top: rem-calc(10);
						right: rem-calc(10);
						color: $blue;

						@include breakpoint(medium only)
						{
							top: rem-calc(4);
							right: rem-calc(4);
						}
					}
				}

				.unavailable__overlay
				{
					// if gridPanel item has class: 'unavailable', display for .unavailable__overlay should be changed to flex.
					display: none;
					align-items: center;
					width: 100%;
					height: 100%;
					position: absolute;
					background: rgba(200, 200, 200, 0.7);

					span 
					{
						margin: auto;
						text-align: center;
						font-family: 'Avenir-Heavy';
						font-size: rem-calc(11);
						color: $blue;
					}
				}

				.form-error
				{
					position: absolute;
					top: rem-calc(10);
					left: rem-calc(5);
				}
			}

			.gridPanel__meta
			{
				margin-bottom: rem-calc(30);
				padding: rem-calc(10) 0 rem-calc(20);

				h4
				{
					color: $blue;
					font-weight: 400;
					font-family: 'SofiaPro-Light';
					font-size: rem-calc(14);
				}

				div
				{
					color: $med-blue;
					font-size: rem-calc(14);
				}

				.button__large__blue 
				{
					margin-top: rem-calc(10);
				}
			}

			.gridPanel__options, .gridPanel__options__visible
			{
				position: absolute;
				top: 0;
				right: 0;
				width: calc(100% - 2vw);
				margin: 1vw;
				box-sizing: border-box;
				//top: 0px;
				//right: 0px;
				cursor: pointer;
				z-index: 2;

				@include breakpoint(medium only)
				{
					//top: 10px;
					//right: 10px;
					//left: 0px;
				}

				.button__inline__block
				{
					@include breakpoint(medium down)
					{
						width: 100% !important;
					}

					span
					{
						margin: 0px;
					}

					padding-top: rem-calc(13) !important;
					padding-left: rem-calc(12) !important;
					padding-right: rem-calc(12) !important;

					&:last-child
					{
						padding-top: rem-calc(1) !important;
					}
					@include breakpoint(medium down)
					{
						padding-left: rem-calc(8) !important;
						padding-right: rem-calc(8) !important;
					}
				}

				.quantity__input
				{
					margin-bottom: rem-calc(25);

					input[type="number"]
					{
						margin: 0px;
						border-top: 2px solid $xlight-grey;
						border-bottom: 2px solid $xlight-grey;
						padding-top: rem-calc(3) !important;
						background: $white;
						box-shadow: none;
						color: $blue !important;
					}

					.button__inline__block
					{
						&:last-child
						{
							padding-top: rem-calc(13) !important;
						}
					}
				}

				input[type="submit"],.product__quantity__control
				{
					width: 100%;
					border: none;
					border-radius: rem-calc(20);
					padding: rem-calc(10) !important;
					padding-top: rem-calc(14) !important;
					background: $light-blue ;
					color: $dark-blue;
				}
			}

			.gridPanel__options__visible
			{
				display:block;
			}

		}
	}
}
.edit-and-delete-line {
	margin: -10px 0 6px;
	a {
		font-size: 12px;
	}
}



$qty-controls-width: 120px;
$qty-controls-input-width: 60px;
$qty-controls-button-height: 40px;
@mixin product-panel-button-border {
	border: 2px solid $medium-blue;
}
@mixin product-panel-button-hover {
	transition: background-color 0.1s linear;
	background-color: #fff;
	&:hover {
		background-color: $light-gray;
	}
	cursor: pointer;
}
@mixin plus-button-style {
	width: 31px;
	height: 32px;
	padding: 6px;
	display: block;
	position: absolute;
	right: 0;
	top: 0;
	border-radius: 4px;
	@include product-panel-button-border;
	@include product-panel-button-hover;
}
.plus-button {
	@include plus-button-style;
}


.product-panel {
	.gridPanel__options__visible {
	}
	position: relative;
	.qty-controls-expander {
		@include plus-button-style;
		&.display-none {
			display: none;
		}
		&:before {
			font-size: 14px;
		}
	}
	.panel-inputs {
		input.text-input {
			width: 100%;
			@include product-panel-button-border;
			height: 32px;
			text-align: right;
			padding-right: 6px;
			padding-top: 3px;
			border-radius: 5px;
		}
		.qty-controls, .cancel, .add-to-quote, .cancel-small {
			width: $qty-controls-width;
			height: $qty-controls-button-height;
			margin-bottom: 2px;
		}
		.qty-controls {
			width: $qty-controls-width;
			position: absolute;
			right: 0;
			top: 36px;
			.decrease-qty, .increase-qty {
				display: block;
				float: left;
				width: ( ($qty-controls-input-width + 4px) / 2 );
				height: $qty-controls-button-height;
				padding-top: 7px;
				@include product-panel-button-border;
				@include product-panel-button-hover;
			}
			.decrease-qty {
				border-radius: 50px 0 0 50px;
				padding-left: 12px;
			}
			.increase-qty {
				border-radius: 0 50px 50px 0;
				padding-left: 11px;
			}
			input {
				display: block;
				float: left;
				margin: 0 -2px;
				width: $qty-controls-input-width;
				height: $qty-controls-button-height;
				box-shadow: none;
				border: none;
				&::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}
				-moz-appearance: textfield;
				text-align: center;
				@include product-panel-button-border;
			}
		}
	}
	.exit-buttons {
		position: absolute;
		right: 1vw;
		bottom: 1vw;
		z-index: 5;
		.exit-buttons-bottom-66 & {
			bottom: calc(57px + 1vw);
		}
		.add-to-quote, .cancel {
			display: block;
			width: $qty-controls-width;
			height: $qty-controls-button-height;
		}
		.add-to-quote, .cancel, .cancel-small span {
			border-radius: 50px;
			display: block;
			text-align: center;
			@include product-panel-button-border;
			@include product-panel-button-hover;
		}
		.cancel {
			background-color: #fff;
			padding-top: 8px;
			margin-bottom: 4px;
		}
		.cancel-small {
			position: relative;
			span {
				width: $qty-controls-button-height;
				height: $qty-controls-button-height;
				position: absolute;
				right: 0;
				bottom: 0;
				color: $medium-blue;
				padding-top: 6px;
				i:before {
					font-size: 16px;
					position: relative;
					top: 2px;
				}
			}
		}
		.add-to-quote {
			background-color: #fff;
			padding-top: 5px;
		}
	}
}


/* temp fixes */

//.admin__grid .gridPanel .gridPanel__inner {
//	.gridPanel__options, .gridPanel__options__visible {
//		@include breakpoint(medium down) {
//			position: absolute;
//			top: 0.624rem;
//			right: 0.624rem;
//		}
//		.button__inline__block:last-child {
//			padding-top: rem-calc(13) !important;
//			background-color: #fed !important;
//		}
//		.button__inline__block:nth-child(2) {
//			@include breakpoint(medium down) {
//				width: $qty-controls-input-width !important;
//				padding-top: 3px !important;
//			}
//		}
//	}
//}

.gallery__block {
	padding-bottom: 20px;
	img {
		width:100%;
	}
}