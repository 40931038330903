.quote__products
{
	.top__bar
	{
		margin-bottom: rem-calc(20);

		.left 
		{
			a 
			{
				color: $grey;
				font-size: rem-calc(14);
			}
		}

		.middle
		{
			.category__name
			{
				color: $blue;
				font-size: rem-calc(16);
			}

			.amount__available
			{
				color: $grey;
				font-size: rem-calc(14);
			}
		}

		.right
		{
			.product__search 
			{
				#product__searchbar
				{
					display: inline-block;
					background: none;
					border: none;
					box-shadow: none;
					max-width: rem-calc(160);
					margin: 0px;
					padding: 0px;
					position: relative;
					bottom: rem-calc(7);
					font-size: rem-calc(14);
					color: $blue;
					text-align: right;
					padding-right: rem-calc(5);

					&::placeholder
					{
						color: $blue;
					}
				}

				label 
				{
					display: inline-block;

					.icon-search
					{
						color: $blue;
						position: relative;
						bottom: rem-calc(4);
						font-size: rem-calc(18);
					}
				}
			}
		}
	}
}