.dashboard
{
	padding-top: rem-calc(50);

	.dashboard__block
	{
		@include breakpoint(medium down)
		{
			margin-bottom: rem-calc(40);
		}
	}
}