.ui-datepicker
{
	padding: 0 rem-calc(12) rem-calc(15);
	@include border-radius(10px);
	background: $white;
	box-shadow: 0 0 15px rgba($black, 0.1);
	z-index: 5000 !important;

	.ui-datepicker-header
	{
		height: rem-calc(60);
		margin: 0 rem-calc(-12) rem-calc(10);
		border-bottom: 1px solid lighten($black, 88.5%);
		@include clearfix();
		position: relative;

		.ui-datepicker-prev,
		.ui-datepicker-next
		{
			height: rem-calc(60);
			color: lighten($black, 70%);
			position: absolute;
			left: 0;
			top: 0;

			.ui-icon
			{
				color: transparent;

				&:before
				{
					display: block;
					padding: rem-calc(10) rem-calc(15);
					color: $blue;
					font-size: rem-calc(26);
					font-family: 'FontAwesome';
					content: '\f104';
				}
			}

			&:hover
			{
				text-decoration: none;
			}
		}

		.ui-datepicker-next
		{
			left: auto;
			right: 0;

			.ui-icon
			{
				&:before
				{
					content: '\f105';
				}
			}
		}

		.ui-datepicker-title
		{
			padding: rem-calc(15) 0;
			text-align: center;
			color: $blue;
			font-size: rem-calc(18);
		}
	}

	.ui-datepicker-calendar
	{
		margin: 0;

		thead
		{
			border: 0;
			background: $white;

			th
			{
				padding: rem-calc(8) rem-calc(12);
				color: $blue;
				font-size: rem-calc(18);
			}
		}


		tbody
		{
			border: 0;
			background: none;

			tr
			{
				&:nth-child(even)
				{
					background: none;
				}
			}

			td
			{
				padding: rem-calc(8) rem-calc(12) rem-calc(6);
				text-align: center;
				color: $blue;
				font-size: rem-calc(18);

				a
				{
					color: $blue;

					&:hover
					{
						text-decoration: none;
					}
				}

				&:hover
				{
					@include border-radius(8px);
					background: $light-blue;
					color: $white;

					a
					{
						color: $white;
					}
				}

				&.ui-datepicker-today
				{
					@include border-radius(8px);
					background: lighten($black, 95%);

					&:hover
					{
						@include border-radius(8px);
						background: $light-blue;
						color: $white;

						a
						{
							color: $white;
						}
					}
				}
			}
		}
	}
}



.date__time__picker
{
	margin: 0 rem-calc(-5);
	@include clearfix();

	.date__picker
	{
		float: left;
		width: 50%;
		padding: 0 rem-calc(5);
	}

	.time__picker
	{
		float: right;
		width: 50%;
		padding: 0 rem-calc(5);

		.select,
		.divider
		{
			float: left;
			width: 45%;
		}

		.divider
		{
			width: 10%;
			line-height: rem-calc(60);
			text-align: center;
		}
	}
}