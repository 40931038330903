.help-block, .alert
{
	display: block;
	text-align: center;
}
.help-block
{
	color: $red;
}
.alert
{
	color: $red;
}
