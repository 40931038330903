$white: #fff;
$black: #000;
$red: #FF7469;
$green: #479d5c;
$leaf-green: #81C24B;
$pale-blue: #DAEDF8;
$light-blue: #A8CFD5;
$light-blue-hover: darken($light-blue, 10%);
$medium-blue: #A6AFBC;
$med-blue: #778896;
$blue: #24405E;
$dark-blue: #223A66;
$off-white: #F8F4F2;
//greys
$xxlight-grey: #f6f6f6;
$xlight-grey: #d8d8d8;
$light-grey: #afafaf;
$grey: #828282;
$dark-grey: #565656;
$xdark-grey: #3a3a3a;
$xxdark-grey: #212121;
$cool-grey-light: #E5E8F0;
$cool-grey-dark: #B2BBD4;

$success-color: #43AC6A !default;   // sea-green
$warning-color: #f08a24 !default;   // carrot
$error-color: #FF7469 !default;   // red

// light blue for links is $primary-color
