.quantity__input {
  ::-webkit-inner-spin-button { -webkit-appearance: none;}
  ::-webkit-outer-spin-button { -webkit-appearance: none;}
  .product__quantity__input
  {
    width: 40px;
    height: rem-calc(41px);
    background: #1779BA;
    color: white;
    -moz-appearance: textfield;
    text-align: center;
    border: none;
  }
}
