ul.accordion
{
  li.accordion-item
  {
    padding: rem-calc(10);

    &.is-active
    {
      margin-bottom: rem-calc(20);
      border: 1px solid $light-blue;
    }

    .accordion-title
    {
      border: none;
      margin-bottom: rem-calc(5);
      padding: 0px;

      &:hover
      {
        background: transparent;
      }

      &:focus
      {
        background: transparent;
      }
    }

    .accordion-content
    {
      border: none;
      padding: 0px;
    }
  }
}
