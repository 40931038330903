@import 'variables';


@import "./node_modules/foundation-sites/scss/foundation";
@import "./node_modules/font-awesome/scss/font-awesome";
@import '../../../../node_modules/magnific-popup/dist/magnific-popup.css';
@import '../../../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.css';
// /home/dcodegroup/work/kontrast/kontrastinteriors/node_modules/magnific-popup/src/css/main.scss
// Foundation
@include foundation-global-styles;
@include foundation-grid;
@include foundation-flex-grid;
@include foundation-flex-classes;
@include foundation-typography;
@include foundation-forms;
@include foundation-button;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;

// Settings
@import "settings/settings";

// Globals
@import 'global/colors';
@import 'global/typography';
@import 'global/mixins';
@import 'global/custom-ui';

// Base
@import 'base';

// Modules
@import 'modules/header';
@import 'modules/footer';

// Components
@import 'components/nav';
@import 'components/buttons';
@import 'components/lists';
@import 'components/forms';
@import 'components/slider';
@import 'components/dropzone';
@import 'components/flash_notice';
@import 'components/datepicker';
@import 'components/side_bar';
@import 'components/dialog';
@import 'components/help-block';
@import 'components/visual-search';
@import 'components/alert-box';
@import 'components/arrows';
@import 'components/top-bar';
@import 'components/admin-list-bar';
@import 'components/selectize';
@import 'components/tabs';
@import 'components/autocomplete';
@import 'components/quantity';
@import 'components/accordion';

// Admin Templates
@import 'templates/login';
@import 'templates/quote-list';
@import 'templates/quote-products';
@import 'templates/pay-page';
@import 'templates/dashboard';
@import 'templates/gallery';
@import "templates/gpor-gantt";

// Structure
// TODO: Change for summary
@import '../structure/pdf';