.arrow__right
{
	position: relative;

	&:before,
	&:after
	{
		content: "";
		display: block;
		position: absolute;
		border-color: $blue;
		right: rem-calc(-25);
	}

	&:before
	{
		width: rem-calc(15);
		height: 0px;
		border-top: 1px solid;
		bottom: rem-calc(7);
	}

	&:after
	{
		width: rem-calc(7);
		height: rem-calc(7);
		border-top: 1px solid;
		border-right: 1px solid;
		transform: rotate(45deg);
		bottom: rem-calc(4);
	}
}