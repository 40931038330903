.admin-header
{
	height: rem-calc(80);
	padding-left: rem-calc(15);
	padding-right: rem-calc(15);
	background: $white;
	box-shadow: 0 3px 3px rgba($black, 0.1);
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	z-index: 10;


	.row
	{
		height: rem-calc(80);
		@include flex-grid-row('collapse','expand');
	}


	.logo
	{
		width: rem-calc(140);
		color: $blue;
	}
}