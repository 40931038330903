body.pdf {
	color: #555;
	font-weight: 500;
	letter-spacing: 1px;

	#content__block {
		font-size: 11px !important;
		margin: 45px 65px;

		h3 {
			background: #23415e;
			color: #fff;
			display: block;
			font-size: 11px !important;
			font-weight: bold;
			letter-spacing: 1px;
			padding: 5px 10px;
		}

		p {
			text-align: justify;
		}

		.grid {
			display: block;
			margin-bottom: 25px;
			position: relative;

			.grid__item {
				border-top: #a9d0d6 dashed 1px;
				display: inline-block;
				float: left;
				min-height: 200px;
				position: relative;
				width: 320px;

				&.right {
					border-right: #a9d0d6 dashed 1px;
				}

				.grid__item--header {
					border-bottom: #a9d0d6 dashed 1px;
					color: #23415e;
					font-weight: 800;
					padding: 3px 10px;
					text-transform: uppercase;
				}
				.grid__item--content {
					padding: 10px;
				}
			}
		}

		table.total {
			background: transparent !important;
			border: 0 !important;
			border-top: #A9D0D6 1px dashed !important;
			width: 100%;

			tr {
				border: 0 !important;

				td {
					background: transparent;
					border: 0 !important;
					padding: 10px 0;
					text-transform: uppercase;
				}

				&:last-child {
					border-top: #000 1px dashed !important;

					td {
						border-top: #000 1px dashed !important;
						font-weight: bold;
					}
				}
			}


		}
	}
}

.quote__summary
{
	$blue: #23415e;
	width: auto;
	max-width: none;

	.admin__list__bar
	{
		margin: 0px;
		//border: none;

		h1
		{
			display: inline-block;
		}

		.generate__PDF
		{
			float: right;
			font-size: rem-calc(14);
			position: relative;
			top: rem-calc(5);
			color: $blue;

			.fa-file-pdf-o
			{
				font-size: rem-calc(20);
				margin-left: rem-calc(15);
				position: relative;
				top: rem-calc(2);
			}
		}
	}

	.quote__summary__content
	{
		padding: rem-calc(35) 0px; //rem-calc(30);
		padding-bottom: 0px;
		font-size: rem-calc(14);
		color: $blue;

		//<table restyling>
			tbody,
			tr,
			th,
			td
			{
				background: none !important;
				background-color: transparent !important;
			}

			table
			{
				margin-bottom: rem-calc(10);
				border: none !important;

				tbody
				{
					border: none !important;

					tr
					{
						th
						{
							padding: 0px;
							font-weight: normal;
						}

						td
						{
							padding: 0px !important;
						}
					}
				}
			}
		//</table restyling>

		// logo
		.logo
		{
			margin-bottom: rem-calc(40);
			width: rem-calc(150);
		}

		//<quote summary blocks>
			.quote__summary__block
			{
				.sub__block
				{
					padding: rem-calc(20);
				}

				.content__heading
				{
					margin-bottom: rem-calc(12);
				}

				// first content block
				&.block__1
				{
					margin-bottom: rem-calc(40);

					td,
					.date
					{
						font-size: rem-calc(16);
						line-height: rem-calc(21);
					}
				}

				// second content block
				&.block__2
				{
					margin-bottom: rem-calc(30);

					.sub__block
					{
						min-height: rem-calc(130);
					}

					table
					{
						margin: 0px;
					}
				}

				// third content block
				&.block__3
				{
					margin-bottom: rem-calc(40);

					table
					{
						tr
						{
							border: 2px solid $blue !important;
							padding: rem-calc(20);

							th,
							td
							{
								width: rem-calc(100);
								text-align: left;
							}

							td
							{
								padding: rem-calc(20) 0px !important;
							}

							td:first-child
							{
								padding-left: rem-calc(20) !important;
							}

							th:nth-child(1),
							td:nth-child(1)
							{
								width: rem-calc(300);
							}

							td:nth-child(even)
							{
								display: none;
							}
						}

						tr:first-child
						{
							border: none !important;
						}
					}
				}

				// fourth content block
				&.block__4
				{
					border-bottom: 2px solid $blue;
					margin-bottom: rem-calc(140);

					td:first-child
					{
						padding-left: rem-calc(20) !important;
					}

					td.first
					{
						padding-left: 0 !important;
					}

					td:last-child
					{
						text-align: right;
						font-size: rem-calc(16);
					}
				}
			}
		//</quote summmary blocks>


		//<specific sections>
			.name,
			.email,
			.address
			{
				td:nth-child(odd)
				{
					display: none;
				}
			}

			.name
			{
				table
				{
					margin: 0px !important;
				}

				tr:last-child
				{
					display: none;
				}
			}

			.email
			{
				margin-top: rem-calc(12);

				tr:first-child
				{
					display: none;
				}
			}

			.address
			{
				tr:nth-last-child(1),
				tr:nth-last-child(2)
				{
					display: inline-block !important;
				}

				tr:nth-last-child(2)
				{
					td:nth-last-child(1)
					{
						&::after
						{
							content: ",";
							padding-right: rem-calc(2);
						}
					}
				}
			}
		//</specific sections>
	}
}

$price-summary-note-widths: 100px;
.price-summary {
	height: 50px;
	[class^='col-'] {
		float: left;
		height: 50px;
		.top, .bottom {
			width: 100%;
			height: 25px;
		}
	}
	.col-1 {
		width: calc(100% - (3 * #{$price-summary-note-widths}));
	}
	.col-gross, .col-gst, .col-total {
		width: $price-summary-note-widths;
		.top, .bottom {
			text-align: right;
		}
	}
}






