.searchContainer
{
	display: inline-block;
	width: calc(100% - 122px);
}

.visual_search,
.search__form
{
	width: 100%;
	margin-right: rem-calc(10);

	input
	{
		display: inline-block;
		//width: calc(100% - 122px);
		margin: 0;
		padding: rem-calc(10) rem-calc(15) rem-calc(7);
		background-color: $white;
		border: 2px solid lighten($black, 90%);
		box-shadow: none;
		height: rem-calc(39);
		font-size: rem-calc(11);
		border-radius: rem-calc(50);

		&:focus
		{
			outline: none;
			box-shadow: none;
			border: 2px solid lighten($black, 90%);
		}
	}

	input[type=submit]
	{
		width: auto;
	}
	
	#search 
	{
		.VS-search
		{
			.VS-search-box-wrapper
			{
				background-color: $white;
				border: 2px solid lighten($black, 90%);
				box-shadow: none;
				height: rem-calc(39);
				border-radius: rem-calc(50);

				.VS-icon
				{
					&.VS-icon-search
					{
						top: rem-calc(13);
						left: rem-calc(11);
					}

					&.VS-icon-cancel
					{
						top: rem-calc(13);
						right: rem-calc(11);
					}
				}

				.VS-search-inner
				{
					top: rem-calc(4);

					.search_input
					{
						.ui-autocomplete-input // input[type="text"]
						{
							height: 100%;
							margin: 0px;
							border: none;
							background: transparent;
							box-shadow: none;
						}
					}

					.search_facet_input_container
					{
						position: relative;
						top: rem-calc(1);
					}

					.search_facet_remove
					{
						position: absolute;
						top: rem-calc(5);
					}

					.search_facet_input
					{
						font-size: rem-calc(11);
						padding: rem-calc(1) 0px 0px rem-calc(5);
					}

					/*
					.search_facet
					{
						padding-left: 0px;

						.category
						{
							width: 100%;
							margin: 0px;
							padding-top: 0px;
							text-align: center;
							position: relative;
							top: rem-calc(3);
						}
					}
					*/
				}
			}
		}
	}
}


.search__form
{
	.visual__search__button__container
	{
		bottom: 0;
	}
}

ul.ui-menu.ui-widget-content
{
	li.ui-menu-item
	{
		padding: rem-calc(10);
	}
}

