.flash__notice
{
	padding: rem-calc(15) rem-calc(30);
	color: $white;
	font-size: rem-calc(12);
	text-align: center;
	position: fixed;
	left: rem-calc(270);
	right: 0;
	top: rem-calc(80);
	z-index: 1;

	@include breakpoint(medium only)
	{
		left: rem-calc(100);
	}

	&:after
	{
		content: '';
	}

	&.success
	{
		background: $light-blue;
	}

	&.error
	{
		background: $red;
	}
}