/* Template 
<div class="alert__box">
    <div class="inner">
        <div class="info__icon__wrapper">
            <div class="info__icon">i</div>
        </div>
        <div class="text">
            <span class="alert__heading">{{ $record->reference_no }}</span> - {{ $record->currentAlertMessage() }} &nbsp;
            {!! Html::delete('admin.quote.deleteAlert', $record->id) !!}
        </div>
        <div class="link__container">
            <a href="#" class="box__link arrow__right">Information Box Link</a>
        </div>
    </div>
</div>
*/
.alert__box
{
	position: relative;
	background-color: $pale-blue;
	padding: rem-calc(40) rem-calc(40) rem-calc(20) rem-calc(60);
	margin-bottom: rem-calc(20);

	.inner
	{
		.info__icon__wrapper
		{
			width: 0px;
			height: 0px;
			position: relative;

			.info__icon 
			{
				width: rem-calc(22);
				height: rem-calc(22);
				position: absolute;
				left: rem-calc(-40);
				text-align: center;
				border-radius: rem-calc(100);
				border: 1px solid $blue;
				color: $blue;
				font-size: rem-calc(14);
				line-height: rem-calc(22);
			}
		}

		.text
		{
			color: $blue;
			font-size: rem-calc(14);
			line-height: rem-calc(22);
			padding-bottom: rem-calc(20);

			.alert__heading
			{
				font-family: 'SofiaMedium';
			}

			.icon-delete
			{
				position: absolute;
				top: rem-calc(15);
				right: rem-calc(15);
				color: $blue;
			}
		}
		
		.link__container
		{
			padding-top: rem-calc(40);

			.box__link
			{
				text-decoration: underline;
				font-family: 'SofiaMedium';
				color: $blue;
			}
		}
	}
}