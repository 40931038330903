.button__container
{
	margin-bottom: rem-calc(20);
	text-align: left;
}

.button__large__blue
{
	height: rem-calc(40);
	padding: rem-calc(9) rem-calc(40) rem-calc(6);
	background-color: $light-blue;
	border: none;
	@include border-radius(28px);
	color: $blue;
	font-weight: 400;
	vertical-align: baseline;
	cursor: pointer;
    text-align: center;
	transition: background-color 0.2s linear;
	&:hover
	{
		background-color: $light-blue-hover;
	}
}

.button__inline__block
{
	display: inline-block;
}

.button__block
{
	display: block;
}

.button__normal
{
	height: rem-calc(40);
	padding: rem-calc(4) rem-calc(20) rem-calc(6);
	text-align: center;
	border: 2px solid #A6AFBC;
	@include border-radius(28px);
	background: $white;
	color: #A6AFBC;
	line-height: rem-calc(30);
	vertical-align: baseline;
	cursor: pointer;

	&:hover
	{
		background: lighten($black, 96%);
	}
}

.button__upload
{
	display: inline-block;
	margin-top: -1px;
	padding: rem-calc(8) rem-calc(15);
	border: 1px solid lighten($black, 80%);
	@include border-radius(5px);
	@include border-top-radius(0);
	background: $white;
	font-weight: 400;
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
	appearance: none !important;
	cursor: pointer;

	input[type="file"]
	{
		display: block;
		width: 100%;
		margin: 0;
		padding: 0;
		opacity: 0;
		cursor: pointer;
		position: absolute;
		right: 0;
		top: 0;
	}
}

.button__group
{
	display: flex;

	.button__inline__block
	{
		padding: rem-calc(12) rem-calc(30) rem-calc(10);
		//border: 2px solid lighten($black, 90%);
		border: 2px solid $xlight-grey;
		background: $white;
		font-size: rem-calc(12);
		//line-height: rem-calc(35);
		text-align: center;
		vertical-align: middle;

		@include breakpoint(medium down)
		{
			padding-left: 20px;
			padding-right: 20px;
		}
		@include breakpoint(medium only)
		{
			body.contact & {
				width: rem-calc(45);
				padding: 0px !important;
				text-align: center;
			}
		}

		&[href],
		&[id]
		{
			height: rem-calc(39);
			line-height: rem-calc(13);
		}

		&[action]
		{
			padding: 0 rem-calc(30);
			position: relative;

			.confirm
			{
				display: block;
				position: relative;
				top: rem-calc(11);
			}
		}

		&:first-child
		{
			@include border-left-radius(28px);
		}

		&:last-child
		{
			@include border-right-radius(28px);
		}

		& + .button__inline__block
		{
			margin-left: -2px;
		}

		transition: background-color 0.2s linear;
		&:hover
		{
			background-color: $xxlight-grey;
		}

		span
		{
			margin: 0 rem-calc(5) 0 0;
		}

		&.less__padding
		{
			padding: rem-calc(12) rem-calc(15) rem-calc(10);
		}
	}

	span
	{
		margin-left: -1px;

		.button__inline__block
		{
			&:first-child
			{
				@include border-left-radius(0);
			}
		}
	}
}


.button__search
{
	margin-left: -1px;
	padding: 0 rem-calc(10);
	border: 1px solid lighten($black, 90%);
	@include border-right-radius(3px);
	background: $white;
	color: $light-blue;
	font-size: rem-calc(12);
	line-height: rem-calc(40);
	text-align: center;
	vertical-align: middle;
	cursor: pointer;

	&:hover
	{
		background: lighten($black, 96%);
	}
}



.button__float__bar
{
	padding: rem-calc(30) rem-calc(40);
	//border-top: 1px solid lighten($black, 95%);
	background: $white;
	box-shadow: 5px 0px 15px 0 rgba($black, 0.1);
	//position: fixed;
	//bottom: 0;
	//left: rem-calc(270);
	//left: rem-calc(100);
	//right: 0;
	z-index: 50;

	@include breakpoint(large)
	{
		//left: rem-calc(200);
	}

	@include breakpoint(xlarge)
	{
		//left: rem-calc(270);
	}
}

.button__round__add
{
	display: block;
	width: rem-calc(30);
	height: rem-calc(30);
	border-radius: rem-calc(50);
	border: 2px solid $light-blue;
	text-align: center;

	span
	{
		position: relative;
		top: rem-calc(6);
		color: $medium-blue;
	}
}

.not-active {
	pointer-events: none;
	opacity: 0.25;
	cursor: not-allowed;
}
