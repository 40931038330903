
$gg-row-outer-height: 56px;
$gg-bar-padding: 11px;
$gg-bar-height: ($gg-row-outer-height - ($gg-bar-padding * 2));

$gg-row-outer-height-for-thinner: 44px;
$gg-bar-padding-for-thinner: 8px;
$gg-bar-height-for-thinner: ($gg-row-outer-height-for-thinner - ($gg-bar-padding-for-thinner * 2));

$gg-row-label-width: 300px;
$gg-header-height: 40px;

$gg-off-white: #f1f2f6;
$gg-stroke: #ddd;

.gpor-gantt {
    background-color: #fff;
    .gg-row-label {
        width: $gg-row-label-width;
        float: left;
        [class^="col-"] {
            float: left;
        }
        .col-expand-hide-clickable {
            width: 30px;
            text-align: center;
        }
        .col-graphic {
            width: 60px;
            text-align: center;
            padding-top: 12px;
        }
        .col-text {
            width: calc(100% - 90px);
        }
        .gpor-gantt-mobile & {
            width: 100%;
        }
    }
    .gg-bar-outer {
        width: calc(100% - #{$gg-row-label-width});
        position: relative;
        float: left;
        padding: 0;
        .grid-line, .gg-head-cell {
            border-left: 1px solid rgba(180,180,180,0.4);
        }
        .gpor-gantt-mobile & {
            width: 100%;
            float: none;
        }
    }
    .gg-header {
        height: $gg-header-height;
        .gg-row-label {
            [class^="col-"] {
                height: $gg-header-height;
            }
        }
        .gg-bar-outer {
            height: $gg-header-height;
            .gg-head-cell {
                height: $gg-header-height;
                position: absolute;
                top: 0;
                .text {
                    width: 100%;
                    text-align: center;
                }
            }
        }
        .col-text, .text {
            margin: 0;
            padding: 12px 0 0;
        }
    }
    .gg-row-outer {
        height: $gg-row-outer-height;
        border-bottom: 1px solid $gg-stroke;
        .gg-row-label {
            height: $gg-row-outer-height;
            [class^="col-"] {
                height: $gg-row-outer-height;
            }
            .col-expand-hide-clickable {
                span {
                    position: relative;
                    top: 15px;
                    left: 5px;
                    color: #aaa;
                    .gpor-gantt-mobile & {
                        left: auto;
                        right: 7px;
                    }
                }
                cursor: pointer;
                transition: background-color 0.1s linear;
                &.desktop-version:hover {
                    background-color: $gg-off-white;
                }
            }
            .col-graphic {
                .fa {
                    font-size: 14px;
                    position: relative;
                    top: 10px;
                }
            }
            .col-text {
                h3 {
                    font-size: 12px;
                    margin: 19px 0 0;
                    .tasks-text {
                        color: #999;
                    }
                }
            }
        }
        .gg-bar-outer, .gg-row {
            box-sizing: content-box;
        }
        .gg-bar-outer {
            height: $gg-row-outer-height;
            .gg-row {
                width: 100%;
                height: $gg-row-outer-height;
                .gg-bar {
                    position: absolute;
                    top: 0;
                    height: $gg-row-outer-height;
                    box-sizing: border-box;
                    .gg-bar-color {
                        border-radius: 100px;
                        position: absolute;
                        left: $gg-bar-padding;
                        width: calc(100% - #{($gg-bar-padding * 2)});;
                        top: $gg-bar-padding;
                        height: $gg-bar-height;
                        overflow: hidden;
                        &.truncated-left {
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                        }
                        &.truncated-right {
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                        }
                        p {
                            color: #fff;
                            font-size: 11px;
                            word-spacing: -1px;
                            margin: (($gg-bar-height / 2) - 8px) 0 0 12px;
                        }
                    }
                }
            }
            .grid-line {
                position: absolute;
                top: 0;
                width: 0;
                height: 100%;
            }
        }
        &.gg-thinner {
            background-color: $gg-off-white;
            height: $gg-row-outer-height-for-thinner;
            .gg-row-label {
                height: $gg-row-outer-height-for-thinner;
                .col-text {
                    h3 {
                        margin-top: 14px;
                    }
                }
            }
            .gg-bar-outer {
                height: $gg-row-outer-height-for-thinner;
                .gg-row {
                    height: $gg-row-outer-height-for-thinner;
                    .gg-bar {
                        height: $gg-row-outer-height-for-thinner;
                        .gg-bar-color {
                            top: $gg-bar-padding-for-thinner;
                            height: $gg-bar-height-for-thinner;
                            //cursor: col-resize;
                            p {
                                margin-top: (($gg-bar-height-for-thinner / 2) - 8px);
                            }
                            .resize-handle {
                                cursor: e-resize;
                            }
                        }
                    }
                }
            }
        }
    }
    .gg-group-rows {
        transition: height 0.3s ease;
        height: 0;
        overflow: hidden;
        .gg-row-sub-group {
            .gg-row-label {
                .col-graphic {
                    padding-top: 0;
                }
            }
            .gg-row-sub-group-head-row {
                background-color: #e1e2ea;
                h3 {
                    font-weight: 600;
                }
            }
        }
    }
    .gg-mobile-info {
        width: 100%;
        border-bottom: 1px solid $gg-stroke;
        padding: 8px 0 6px;
        .mobile-info-row {
            width: 100%;
            height: 45px;
            padding-top: 10px;
        }
    }
    .columns-header-mobile {
        display: none;
        .gpor-gantt-mobile & {
            display: block;
            height: 45px;
            border-bottom: 1px solid $gg-stroke;
            .gg-bar-outer {
                position: relative;
                height: 100%;
                .gg-head-cell {
                    position: absolute;
                    text-align: center;
                    height: 100%;
                    border-color: #f2f2f2;
                    .text {
                        color: #777;
                        margin: 0;
                        padding: 5px 3px 0;
                        height: 100%;
                        font-size: 9px;
                    }
                }
            }
        }
    }
}

.gg-row-outer {
    .color-like-bar .fa {
        color: #888;
    }
    .gg-bar-color {
        background-color: #888;
    }
}
.gg-pink {
    .color-like-bar .fa {
        color: #f55a64;
    }
    .gg-bar-color {
        background-color: #f55a64;
    }
}
.leave-colour{
    .color-like-bar .fa {
        color: #fab;
    }
    .gg-bar-color {
        background-color: #fab;
    }
}
.project-colour{
    .color-like-bar .fa {
        color: rebeccapurple;
    }
    .gg-bar-color {
        background-color: rebeccapurple;
    }
}
.role-colour{
    .color-like-bar .fa {
        color: royalblue;
    }
    .gg-bar-color {
        background-color: royalblue;
    }
}


