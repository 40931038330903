#top__navigation
{
	ul
	{
		list-style: none;
		margin: 0;
		padding: 0;
		font-size: 0;
		float: right;

		li
		{
			display: inline-block;

			a
			{
				display: inline-block;
				padding: rem-calc(7) rem-calc(20) rem-calc(6);
				border: 1px solid transparent;
				color: $dark-blue;
				font-size: rem-calc(13);

				&.logout
				{
					margin-left: rem-calc(10);
					padding: rem-calc(9) rem-calc(30) rem-calc(5);
					border: 2px solid $light-blue;
					border-radius: 28px;
					transition: background-color 0.2s linear;
					&:hover {
						background-color: mix($light-blue, #fff, 20%);
					}
				}

				span
				{
					display: inline-block;
					width: rem-calc(20);
					height: rem-calc(20);
					margin-left: rem-calc(4);
					border: 2px solid $red;
					border-radius: 50%;
					text-align: center;
					line-height: rem-calc(18);
				}
			}
		}
	}
}


#main__navigation {
	width: rem-calc(100);
	@include breakpoint(large) {
		width: rem-calc(200);
	}
	@include breakpoint(xlarge) {
		width: rem-calc(270)
	}
	padding-top: rem-calc(80);
	background: $blue;
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	z-index: 1;
	ul {
		list-style: none;
		margin: 0;
		padding: rem-calc(20) rem-calc(15);
		li {
			margin-bottom: rem-calc(15);
			@include breakpoint(medium down) {
				margin-bottom: rem-calc(18);
			}
			.link {
				display: flex;
				width: 100%;
				//height: rem-calc(40);
				padding: 0 rem-calc(8) 0 0;
				@extend .align-middle;
				color: $light-blue;
				@include breakpoint(medium down) {
					padding: 0px;	
				}
				&.active {
					color: $white;
				}
				a {
					display: block;
					color: $light-blue;
					transition: color 0.2s linear;
					font-size: rem-calc(16);
				}
				.icon {
					display: flex;
					width: 50px;
					padding: 0;
					flex-shrink: 0;
					@extend .align-center;
					font-size: rem-calc(20);
					@include breakpoint(medium down) {
						margin: auto;
					}
					a {
						@include breakpoint(medium down)
						{
							font-size: rem-calc(32);
						}
					}
				}
				.text {
					display: inline-block;
					-webkit-box-flex: 1;
					flex-grow: 1;
					line-height: rem-calc(40);
					.seo {
						display: inline-block;
						float: right;
						font-size: rem-calc(11);
						line-height: rem-calc(40);
					}
					span {
						float: right;
						font-size: rem-calc(12);
						line-height: rem-calc(40);
					}
				}
				transition: color 0.2s linear;
				&:hover,
				&.active {
					color: $white;
					a
					{
						color: $white;
					}
				}
				@include breakpoint(medium down) {
					display: block;
					.icon, .text {
						display: block;
						text-align: center;
						line-height: 1.1;
					}
					.icon {
						a {
							padding: 0;
						}
					}
					.text {
						a {
							font-size: 10px;
							padding: 0;
							span.fa {
								display: none;
							}
						}
						.seo {
							display: none
						}
					}
				}
			}
			ul {
				display: none;
			}
			&.settings {
				ul {
					display: block;
					li {
						display: block;
						.link {
							color: $blue;
							transition: color 0.2s linear;
							a {
								color: $blue;
								transition: color 0.2s linear;
							}
							&:hover,
							&.active {
								color: $white;
								a {
									color: $white;
								}				
							}
						}
					}
				}
				.level {
					width: 100%;
					height: 100%;
					padding: rem-calc(80) 0 rem-calc(16);
					background: $light-blue;
					-webkit-transform: translate3d(-100%,0,0);
				    -moz-transform: translate3d(-100%,0,0);
				    transform: translate3d(-100%,0,0);
				    -webkit-transition: all .4s;
				    -moz-transition: all .4s;
				    transition: all .4s;
					position: absolute;
					left: 0;
					top: 0;
					&.open {
						-webkit-transform: translate3d(0,0,0);
						-moz-transform: translate3d(0,0,0);
						transform: translate3d(0,0,0);
					}
					header {
						height: auto;
						padding: rem-calc(20) rem-calc(20) 0;
						background: none;
						box-shadow: none;
						color: $blue;
						position: relative;
						z-index: 1;
					}

					.back
					{
						display: block;
						width: rem-calc(30);
						height: rem-calc(30);
						background: lighten($black, 90%);
						text-align: center;
						line-height: rem-calc(26);
						font-size: rem-calc(16);
						cursor: pointer;
						position: absolute;
						right: 0;
						top: rem-calc(80);
						z-index: 2;
						span {
							margin-right: rem-calc(5);
							font-size: rem-calc(10);
						}
					}
				}
			}
			&.quick__appointment {
				margin-top: rem-calc(45);
				padding-left: rem-calc(10);
				padding-right: rem-calc(10);
				a {
					display: block;
					padding: rem-calc(10) 0 rem-calc(7);
					border: 2px solid $light-blue;
					border-radius: 28px;
					color: $white;
					font-size: rem-calc(12);
					text-align: center;
					transition: background-color 0.2s linear;
					&:hover, &:active {
						background-color: mix($light-blue, $blue, 20%);
					}
				}
			}
			@include breakpoint(medium down) {
				&.quick__appointment {
					padding: 0;
				}
				&.settings {
					.level {
						header {
							padding: 45px 0 5px;
							text-align: center;
							border-bottom: 1px solid mix($light-blue, $blue, 90%);
						}
					}
				}
			}
		}
	}
}