.gallery
{
	&.grid
	{
		.admin__list__bar
		{
			.bar__right
			{
				.button__group
				{
					text-align: right;
				}
			}
		}

		.admin__grid
		{
			margin-bottom: rem-calc(40);

			.gridPanel
			{
				margin-bottom: rem-calc(20);
			}
		}
	}
}



.gallery-grid {
	width: 100%;
	.dz-preview {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		[class^='dz-'] {
			display: none;
		}
		.dz-image {
			display: block;
		}
	}
}
.grid-cell {
	margin-right: 20px;
	margin-bottom: 20px;
	float: left;
	.cell-inner {
		width: 100%;
		display: block;
	}
	&.dropzone-inline-container {
		.cell-inner {
			.gallery-dropzone {
				height: auto;
			}
		}
	}
	.lg-xcount-6 & {
		width: calc(16.6% - 20px);
	}
	.lg-xcount-5 & {
		width: calc(20% - 20px);
	}
	.lg-xcount-4 & {
		width: calc(25% - 20px);
	}
	.lg-xcount-3 & {
		width: calc(33.3% - 20px);
	}
	.lg-xcount-2 & {
		width: calc(50% - 20px);
	}
	.lg-xcount-2 & {
		width: calc(50% - 20px);
	}
	.lg-xcount-1 & {
		width: 100%;
	}
	@media (max-width: 1100px) and (min-width: 700px) {
		.med-xcount-5 & {
			width: calc(20% - 20px);
		}
		.med-xcount-4 & {
			width: calc(25% - 20px);
		}
		.med-xcount-3 & {
			width: calc(33.3% - 20px);
		}
		.med-xcount-2 & {
			width: calc(50% - 20px);
		}
		.med-xcount-1 & {
			width: 100%;
		}
	}
	@media (max-width: 699px) and (min-width: 450px) {
		.sm-xcount-4 & {
			width: calc(25% - 20px);
		}
		.sm-xcount-3 & {
			width: calc(33.3% - 20px);
		}
		.sm-xcount-2 & {
			width: calc(50% - 20px);
		}
		.sm-xcount-1 & {
			width: calc(100% - 20px);
		}
	}
	@media (max-width: 449px) {
		.xs-xcount-4 & {
			width: calc(25% - 20px);
		}
		.xs-xcount-3 & {
			width: calc(33.3% - 20px);
		}
		.xs-xcount-2 & {
			width: calc(50% - 20px);
		}
		.xs-xcount-1 & {
			width: calc(100% - 20px);
		}
	}
	@media (min-width: 1450px) {
		.xl-xcount-8 & {
			width: calc(12.5% - 20px);
		}
		.xl-xcount-6 & {
			width: calc(16.6% - 20px);
		}
		.xl-xcount-5 & {
			width: calc(20% - 20px);
		}
		.xl-xcount-4 & {
			width: calc(25% - 20px);
		}
		.xl-xcount-3 & {
			width: calc(33.3% - 20px);
		}
		.xl-xcount-2 & {
			width: calc(50% - 20px);
		}
		.xl-xcount-1 & {
			width: 100%;
		}
	}
	form {
		width: 100%;
		text-align: right;
		user-select: none;
	}
}

.height-maker {
	margin-top: 90%;
}
.img-cover, .img-contain, .dropzone-inline-container {
	background-position: center;
	box-sizing: border-box;
	border: 1px solid transparent;
	background-repeat: no-repeat;
}
.img-cover {
	background-size: cover;
	position: relative;
	.overlay-controls {
		position: absolute;
		top: 5px;
		right: 5px;
	}
}
.img-contain {
	background-color: #fff;
	background-size: contain;
}


.sortable {
	.ui-state-highlight {
		height: 8.0em;
		line-height: 8.0em;
		width: 12.0em;
		background-color: $light-blue;
		float: left;
	}
}
.pos-absolute {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

