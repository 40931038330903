.selectize-control
{
	.selectize-input
	{
		border-radius: 0px;
		padding: rem-calc(18) rem-calc(15) rem-calc(14);
		box-shadow: none;
		font-size: rem-calc(14);
		border: none !important;
		height: 53px !important;
		line-height: 38px !important;
	}
}