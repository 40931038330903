ul.tabs 
{
	margin-bottom: rem-calc(-2);
	border: none;
	background: transparent;

	li
	{
		border: 2px solid $blue;
		border-bottom: 2px solid $blue;
		border-right: none;
		border-bottom: none;

		&:last-child
		{
			border-right: 2px solid $blue;
		}

		&.is-active
		{
			border-bottom: none;

			a 
			{
				// border-bottom set to off-white to match background of general pages.
				border-bottom: 2px solid $off-white;
				background: transparent;
				color: $blue;
			}
		}

		a 
		{
			border-bottom: 2px solid $blue;
			background: #eee;
			color: #999;

			&:focus
			{
				outline: none;
			}

			&:hover 
			{
				color: $blue;
			}
		}
	}
}

.tabs-content
{
	margin: 0px rem-calc(-1);
	background: transparent;

	.tabs-panel
	{
		border: 2px solid $blue;
	}
}