form
{

	/*&.search__form
	{
		input[type="text"]
		{
			height: auto;
			margin-bottom: 0;
			padding: rem-calc(12) rem-calc(15) rem-calc(8);
			border: 2px solid $blue;
			background-color: transparent;
			background-position: right 3px center;
			background-size: 20px;
			box-shadow: none;
			color: $blue;
			font-size: rem-calc(14);
			//line-height: rem-calc(14);

			@include placeholder
			{
				color: $med-blue;
			}
		}

		position: relative;


	}*/

	&.edit__form
	{
		legend
		{
			display: block;
			width: 100%;
			padding-top: rem-calc(30);
			margin-bottom: rem-calc(20);
			padding-bottom: rem-calc(10);
			border-bottom: 2px solid $blue;
			color: $blue;
			font-family: 'SofiaPro-Light';
			font-size: rem-calc(18);
			font-weight: 400;

			&.hide
			{
				display: none;
			}
		}

		fieldset
		{
			margin-bottom: rem-calc(40);
		}

		label
		{
			margin: 0 0 rem-calc(-2) 0;
			color: $blue;
			font-size: rem-calc(13);
		}


		.form__row
		{
			margin: 0 0 rem-calc(10);

			&.bannerImage
			{
				label
				{
					font-weight: 400;
				}
			}

			&.half
			{
				.half__row
				{
					@include flex-grid-row('collapse', 'expand');
				}
			}
			&.half-desktop {
				@media (min-width: 900px) {
					.half__row
					{
						@include flex-grid-row('collapse', 'expand');
					}
				}
			}
			&.no__label
			{
				label
				{
					display: none;
				}
			}

			.input-group-label
			{
				padding: 0 rem-calc(15);
				color: $blue;

				&.larger
				{
					font-size: rem-calc(24);
				}
			}

			.input-group
			{
				&.input-group-right
				{
					.input-group-label
					{
						// border: 2px solid $blue;
						border: 0 none;
						border-left: 0;
						background: $white;
					}

					input[type="text"],
					input[type="email"],
					input[type="password"]
					{
						border-right: 0;
						padding-right: 0;
					}
				}

				&.input-group-left
				{
					.input-group-label
					{
						// border: 2px solid $blue;
						border: 0 none;
						border-right: 0;
						background: $white;
					}

					input[type="text"],
					input[type="email"],
					input[type="password"]
					{
						border-left: 0;
						padding-left: 0;
					}
				}
			}

			.form__col
			{
				@include flex-grid-column('expand', 0);

				padding-right: rem-calc(8);
				padding-left: rem-calc(8);
				&:first-child
				{
					padding-left: 0;
				}
				&:last-child
				{
					padding-right: 0;
				}
			}
		}


		input[type="text"],
		input[type="email"],
		input[type="password"],
		textarea,
		select
		{
			height: auto;
			padding: rem-calc(18) rem-calc(15) rem-calc(14);
			// border: 2px solid $blue;
			border: 0 none;
			// background-color: transparent;
			background-color: $white;
			background-position: right 3px center;
			background-size: 20px;
			box-shadow: none;
			color: $blue;
			font-size: rem-calc(14);
			//line-height: rem-calc(14);

			/*
			@include placeholder
			{
				color: $med-blue;
			}
			*/

			::-webkit-input-placeholder { /* Chrome/Opera/Safari */
				color: #e0e0e0;
			}
			::-moz-placeholder { /* Firefox 19+ */
				color: #e0e0e0;
			}
			:-ms-input-placeholder { /* IE 10+ */
				color: #e0e0e0;
			}
			:-moz-placeholder { /* Firefox 18- */
				color: #e0e0e0;
			}
		}

		select
		{
			background-image: url('/img/admin/dropdown.svg');
		}
	}
}


body
{
	&.edit
	{
		.inner__content__block
		{
			@extend .large-8;
		}
	}
}

#referralSelect,
#customerSelect
{
	margin-bottom: rem-calc(30);
}

.xero {
	padding: 0 20px !important;

	.account {
		margin-top: 20px;
	}

	.disabled {
		background: lightgrey;
		color: white;
	}
}