.image-dropzone, .gallery-dropzone
{
	width: 100%;
	height: rem-calc(140);
	border: 2px dashed $xlight-grey;
	border-radius: 8px;
	background-color: #fff;
	transition: background-color 0.2s linear;
	cursor: pointer;
	&:hover {
		background-color: mix(#fff, $xlight-grey, 70%);
	}
	@extend .align-middle;
	position: relative;

	&:after
	{
		text-align: center;
		content: '\f030';
		font-family: 'FontAwesome';
		color: $light-blue;
		font-size: rem-calc(25);
		margin: rem-calc(-18) 0 0 rem-calc(-13);
		position: absolute;
		left: 50%;
		top: 50%;
	}


	.dz-default,
	.dz-preview
	{
		height: 100%;
		margin: 0;
		border: 2px dotted transparent;
		border-radius: 0;
		background: transparent;

		span
		{
			display: none;
		}

		.dz-image
		{
			width: 100%;
			height: 100%;
			border-radius: 0;
		}
	}
}


.panel
{
	.panel__section
	{
		margin-bottom: rem-calc(50);

		.images
		{
			//@include flex-grid-row('collapse', 'expand');
			width: 100%;
			//margin-left: rem-calc(-15);
			//margin-right: rem-calc(-15);

			.images__list--image
			{
				//@include flex-grid-column(4);
				//height: rem-calc(140);
				//margin-bottom: rem-calc(30) !important;
				//padding-left: rem-calc(15) !important;
				//padding-right: rem-calc(15) !important;
				overflow: hidden;
				
				img 
				{
					width: 100%;
					margin-bottom: rem-calc(10);

					@include breakpoint(large up)
					{
						margin-bottom: rem-calc(20);
					}
				}
			}

			.images__list
			{
				@include flex-grid-row('collapse', 'expand');
				margin-left: rem-calc(-15);
				margin-right: rem-calc(-15);
			}

			span
			{
				display: none;
				width: 100%;
				color: $light-blue;
				font-size: rem-calc(40);
				text-align: center;
			}

		}

		&.drop
		{
			margin-bottom: 0px;

			> div 
			{
				margin-bottom: rem-calc(10);

				@include breakpoint(large up)
				{
					margin-bottom: rem-calc(20);
				}

				&:last-child
				{
					margin-bottom: 0px;
				}

				img 
				{
					width: 100%;
				}
			}
		}
	}
}