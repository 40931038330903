.pay__page
{
	.upper
	{
		padding: rem-calc(50) 0px;
		text-align: center;

		.logo
		{	
			img 
			{
				width: rem-calc(160);
				height: auto;
				margin-bottom: rem-calc(35);
			}
		}

		.heading
		{
			margin: 0px;
			font-family: 'SofiaPro-Light';
			color: $blue;
		}
	}

	.middle
	{
		margin-bottom: rem-calc(30);
		color: $blue;

		ul 
		{
			margin: 0px;
			list-style: none;

			li 
			{
				font-size: rem-calc(14);
				line-height: rem-calc(20);

				.list__marker
				{
					display: inline-block;
					vertical-align: top;
					color: $light-grey;
				}

				.text
				{
					display: inline-block;
				}
			}
		}

		.left
		{
			border: 1px solid $blue;
			padding: rem-calc(30) rem-calc(20);

			.customer__details
			{
				margin-bottom: rem-calc(20);

				.name
				{
					margin-bottom: rem-calc(5);
					font-family: 'SofiaMedium';
					font-size: rem-calc(16);
				}

				.list__marker
				{
					margin-right: rem-calc(5);
				}
			}

			.dates
			{
				margin-bottom: rem-calc(15);
				border-bottom: 1px solid $blue;
				padding-bottom: rem-calc(15);
				font-size: rem-calc(14);
				line-height: rem-calc(20);
				
				.heading
				{
					margin-bottom: rem-calc(10);
					font-family: 'SofiaMedium';
					font-size: rem-calc(14);
				}

				.list__marker
				{
					width: rem-calc(90);
				}
			}

			.items
			{
				margin-bottom: rem-calc(25);
				border-bottom: 1px solid $blue;
				padding-bottom: rem-calc(15);

				> div:last-child
				{
					margin-bottom: 0px;
				}

				.heading
				{
					margin-bottom: rem-calc(10);
					font-family: 'SofiaMedium';
					font-size: rem-calc(14);
				}

				.item__row
				{
					margin-bottom: rem-calc(5);
					font-size: rem-calc(14);
					line-height: rem-calc(20);
				}
			}

			.price__summary
			{
				padding-top: rem-calc(15);
				padding-bottom: rem-calc(20);

				.heading
				{
					margin-bottom: rem-calc(5);
					font-family: 'SofiaMedium';
					font-size: rem-calc(14);
				}

				.total__price__label
				{
					border: none;
					text-align: left;
					font-size: rem-calc(14);
					color: $light-grey;

					&:focus
					{
						outline: none;
					}
				}

				.total__price__value
				{
					text-align: right;
					font-family: 'SofiaMedium';
					font-size: rem-calc(16);
				}
			}
		}

		.right
		{
			form
			{
				.form__row
				{
					border-radius: 0px;
					margin-bottom: rem-calc(25);

					&.card__holder
					{
						padding-right: rem-calc(20);
					}

					.form__label
					{
						margin-bottom: rem-calc(5);
						font-size: rem-calc(14);
						line-height: rem-calc(20);
						color: $light-grey;
					}

					.form__field
					{
						& input[type="text"]
						{
							height: rem-calc(40);
							border: none !important;
							background: white;
						}
					}
					
					.select__wrapper
					{
						position: relative;
						background: white;

						select
						{
							height: rem-calc(40);
							position: relative;
							z-index: 1;
							margin: 0px;
							border: none !important;
							padding: 0px rem-calc(10);
						}

						.down__arrow
						{
							width: rem-calc(20);
							height: rem-calc(20);
							position: absolute;
							z-index: 0;
							border-top: 1px solid $blue;
							border-right: 1px solid $blue;
							transform: rotate(135deg);
							top: rem-calc(2);
							right: rem-calc(5);
						}
					}

					&.card__cvc
					{
						.information__icon
						{
							display: inline-block;
							width: rem-calc(12);
							height: rem-calc(12);
							position: relative;
							top: rem-calc(4);
							left: rem-calc(4);
							border: 1px solid $light-grey;
							border-radius: 50%;

							.inner
							{
								position: relative;
								bottom: rem-calc(5);
								left: rem-calc(4);
								font-size: rem-calc(10);
								color: $light-grey;
							}
						}
						
						.secure__payment
						{
							position: relative;
							top: rem-calc(22);

							.secure__icon
							{
								display: inline-block;
								margin-right: rem-calc(12);
								font-size: rem-calc(36);
							}

							.secure__text
							{
								display: inline-block;
								font-size: rem-calc(10);
								line-height: rem-calc(12);

								.lead__text
								{
									color: $blue;
								}

								.sub__text
								{
									color: $light-grey;
								}
							}
						}
					}

					&.buttons
					{
						padding-top: rem-calc(20);

						#pay-btn, #pay-now
						{
							margin-right: rem-calc(10);
							border: 2px solid $leaf-green;
							border-radius: rem-calc(20);
							padding: rem-calc(10) rem-calc(25);
							background: $leaf-green;
							font-family: 'SofiaMedium';
							font-size: rem-calc(11);
							line-height: rem-calc(12);
							color: $white;
						}

						#pay-later, #alternate-pay
						{
							border: 2px solid $light-blue;
							border-radius: rem-calc(20);
							padding: rem-calc(10) rem-calc(25);
							background: transparent;
							font-family: 'SofiaMedium';
							font-size: rem-calc(11);
							line-height: rem-calc(12);
							color: $blue;
						}
					}
				}
			}
		}
	}

	.overlay{
		position: fixed;
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
		z-index: 1000000; /* keep on top of other elements on the page */
		background: rgba(255, 255, 255, 0.5);
	}

	.spinner{
		position: absolute !important;
		left: 50%;
		top: 50%;
	}

	.overlay .text
	{
		width: 100%;
		position: absolute;
		top: 50%;
		text-align: center;
		color: $blue;
	}

	.overlay .text span
	{
		position: relative;
		left: 5px;
		bottom: 7px;
	}


	.stripe-invalid-input
	{
		border-color: #FF7469 !important;
		background-color: rgba(255, 46, 4, 0.24) !important;
	}

	#pay-now
	{
		margin-right: rem-calc(10);
		border: 2px solid $leaf-green;
		border-radius: rem-calc(20);
		padding: rem-calc(10) rem-calc(25);
		background: $leaf-green;
		font-family: 'SofiaMedium';
		font-size: rem-calc(11);
		line-height: rem-calc(12);
		color: $white;
	}

	#alternate-pay
	{
		border: 2px solid $light-blue;
		border-radius: rem-calc(20);
		padding: rem-calc(10) rem-calc(25);
		background: transparent;
		font-family: 'SofiaMedium';
		font-size: rem-calc(11);
		line-height: rem-calc(12);
		color: $blue;
	}
}