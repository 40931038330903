#slider
{
	padding: rem-calc(30);
	background: $white;

	h2
	{
		margin: 0;
		color: $blue;
		font-family: 'SofiaPro-Light';
		font-size: rem-calc(24);
	}
	input[type="text"], input[type="email"], textarea, select {
		border: 1px solid $xlight-grey;
	}
	input[type="submit"] {
		border: none;
		font-size: 20px;
		padding-top: 8px;
		float: right;
		margin-top: 10px;
	}
	.bannerImage {
		padding: 20px 0;
	}
}

.flex-title-strip {
	display: flex;
	width: 100%;
	justify-content: space-between;
	padding-bottom: 10px;
	h2, .close__button {
		display: inline-block;
	}
	.close__button {
		cursor: pointer;
		span {
			&:before {
				font-size: 22px;
				color: $light-blue;
			}
		}
	}
}









