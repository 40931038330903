body
{
	&.login
	{
		height: 100%;
		background: $off-white;

		.login__panel
		{
			height: 100%;

			&>.row
			{
				height: 100%;
			}

			header
			{
				height: auto;
				padding: 0 0 rem-calc(10);
				position: relative;
				background: none;
				box-shadow: none;

				h1
				{
					margin: 0;
					font-size: rem-calc(35);
					font-family: "sofia-pro-soft",sans-serif;
					text-align: center;

					&.logo
					{
						width: rem-calc(230);
						margin: 0 auto rem-calc(40);
					}
				}
			}

			label
			{
				margin: 0 0 rem-calc(10);
				color: $blue;
				font-size: rem-calc(14);
				font-family: 'SofiaPro-ExtraLight';
			}

			.text__inputs
			{
				margin: rem-calc(20) auto;

				.field
				{
					margin: rem-calc(7) auto 0;

					input
					{
						max-width: 500px;
						width: 100%;
						height: 45px;
						padding-left: rem-calc(10);
						border-radius: 5px;
						border: 0;
						background: $white;
						box-shadow: none;
						font-family: 'SofiaPro-ExtraLight';

						&:hover,
						&:focus
						{
							box-shadow: none;
							outline: none;
						}
					}
				}
			}

			p
			{
				text-align: center;

				a
				{
					color: #A6A8AB;
					font-weight: 300;
					font-size: rem-calc(12);
					font-family: 'SofiaPro-Light';
					text-decoration: underline;
				}
			}

			.button__large__blue
			{
				display: block;
				margin: 0 auto;
				background: $light-blue;
				border-color: transparent;
				font-weight: 300;
				font-family: 'SofiaPro-Light';
			}
		}
	}
}



.login__screen
{
	height: 100%;
}