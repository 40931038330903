.admin__list__bar
{
	.row 
	{
		margin: 0px;
		width: 100%;
	}

	.visual__search__button__container
	{
		display: inline-block;
		position: relative;
		bottom: 0;
		left: 4px;
		vertical-align: top;
		input 
		{
			color: $primary-color;
		}
	}

	.title
	{
		position: relative;
		top: rem-calc(8);
	}
}